.featured-blog-post-card {
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
  background: $cyan-darker;
  padding: 0;
  height: 100%;
  
  .featured-blog-post-card__content {
    display: flex;
    align-content: space-evenly;
    flex-wrap: wrap;

    h2 {

    }
  }

  .featured-blog-post-card__photo {
    height: 100%;
    
    img {
      object-fit: cover;
      min-height: 100%;
    }
  }

  .featured-blog-post-card__description-text {
    color: white;
  }
}

.featured-blog-post-card--marine {
  background: $prussian-blue;
}

.only-image-blog-post-card {
  height: 100%;
}

.only-image-blog-post-card__photo {
  height: 100%;
  position: relative;

  img {
    min-height: 100%;
    max-width: 100%;
  }

  &:after {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.1);
  }
}

.only-image-blog-post-card__content {
  top: 200px;
  left: 0;
  padding-left: 40px;
  padding-right: 40px;
  position: absolute;
  max-width: 100%;
}
